import styled from 'styled-components';

export const Slider = styled.div`
    position: relative;

    > div {
        display: flex;
        overflow-x: scroll;
        position: relative;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    > footer {
        position: absolute;
        right: 4.5%;
        bottom: 13%;
        display: flex;
        gap: 1rem;
        justify-content: end;

        @media (max-width: 640px) {
            padding-top: 1rem;
            position: relative;
            inset: 0;
            justify-content: center;
        }

        > label {
            cursor: pointer;
            display: block;

            > span {
                display: block;
                text-indent: -999em;
                border-radius: 999em;
                height: 13px;
                width: 13px;
                background-color: rgba(67, 146, 232, 0.2);
                transition: background-color .2s ease-in-out;
            }

            input {
                display: none;

                &:checked + span {
                    background-color: rgba(67, 146, 232, 1);
                }
            }
        }
    }
`;

export const Item = styled.div`
    border-radius: 4px;
    overflow: hidden;
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;

    img {
        width: 100%;
        height: 356px;
        border-radius: 4px;
        pointer-events: none;
        object-fit: cover;
        object-position: right center;

        @media (max-width: 680px) {
            height: 300px;
        }

        @media (max-width: 420px) {
            height: 185px;
        }
    }
`;

export const Link = styled.a`
    direction: rtl;
    border-radius: 4px;
    overflow: hidden;
    display: block;
    position: relative;

    img {
        min-width: 975px !important;
        height: 365px !important;
        border-radius: 4px;
        pointer-events: none;
    }
`;
