import { memo, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { sleep } from '@src/utils';
import { usePushEffect, useInterval } from '@src/utils/hooks';
import * as Styles from './banners.styled';

const Banners = ({ banners }) => {
    const { locale } = useRouter();
    const [selected, setSelected] = useState(0);
    const [play, setPlay] = useState(true);

    const bigBanners = useMemo(() => (
        banners.filter((banner) => banner.tag.startsWith('banner.big')).filter((banner) => !!banner.data.find((d) => d.value.trim() !== ''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [locale, banners]);

    function handleBanner(i) {
        return async () => {
            setPlay(false);
            const target = document.getElementById(`banner-${i}`);

            if (target) {
                target.parentNode.scrollLeft = target.offsetLeft;
                setSelected(i);

                await sleep(500);
                setPlay(true);
            }
        };
    }

    usePushEffect('banners');

    useEffect(() => {
        const target = document.getElementById('banner-0');
        if (target && target?.parentNode) {
            target.parentNode.scrollLeft = target.offsetLeft;
        }
    }, []);

    useInterval(() => {
        if (selected === 2) {
            return handleBanner(0)();
        }

        return handleBanner(selected + 1)();
    }, play ? 5000 : null);

    return (
        <Styles.Slider id="banners">
            <div>
                {bigBanners.map((banner, i) => (
                    <Styles.Item key={banner.tag} id={`banner-${i}`} tabIndex="0" dangerouslySetInnerHTML={{ __html: banner.data.find((item) => item.language === locale)?.value || '' }} />
                ))}
            </div>
            <footer>
                {bigBanners.map((banner, i) => (
                    <label key={banner.tag} htmlFor={`radio-${i}`}>
                        <input id={`radio-${i}`} type="radio" onChange={handleBanner(i)} name="banner" checked={i === selected} /> <span>{i}</span>
                    </label>
                ))}
            </footer>
        </Styles.Slider>
    );
};

export default memo(Banners);
